<template>
  <v-container fluid>
    <v-row align="center" justify="center" class="mb-3">
      <v-col cols="4" md="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="搜尋(會員ID)"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          outlined
          dense
          hide-details
          label="已驗證"
          v-model="isVerified"
        ></v-checkbox>
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          outlined
          dense
          hide-details
          label="已註冊格主"
          v-model="isOwner"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-alert type="info" dense>
      1. 已驗證名字和電話的使用者才能新增為格主
    </v-alert>
    <v-alert type="info" dense>
      2. 底下設定有一番賞的格主無法直接移除格主身份
    </v-alert>
    <div class="text-right">
      <v-btn @click="exportExcel" color="secondary">匯出 </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="filterData"
      :mobile-breakpoint="0"
      item-key="index"
      :footer-props="{
        itemsPerPageText: '每頁幾筆',
        itemsPerPageOptions: [10, 20, -1],
      }"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ dayjs(item.createdAt).format("YYYY/MM/DD") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="openDialog(item, 'topUp')"
          color="primary"
          small
          class="mr-2"
        >
          儲值
        </v-btn>
        <v-btn @click="openDialog(item, 'verify')" color="success" small>
          更新
        </v-btn>
      </template>
      <template v-slot:item.verify="{ item }">
        <div>
          <v-checkbox
            class="empty-label"
            :input-value="item.isVerified"
            disabled
            dense
            hide-details
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:item.ichiban="{ item }">
        <div>
          <v-tooltip
            bottom
            v-if="
              !item.isVerified ||
              (ownerIds.includes(item.userID) &&
                ownerMap[item.userID].ichibans.length > 0)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  class="empty-label"
                  :input-value="ownerIds.includes(item.userID)"
                  disabled
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </template>
            <span class="caption"
              >{{ item.isVerified ? "已擁有一番賞" : "尚未驗證" }}
            </span>
          </v-tooltip>
          <v-checkbox
            v-else
            class="empty-label"
            :input-value="ownerIds.includes(item.userID)"
            @click="updateOwner(item, ownerIds.includes(item.userID))"
            readonly
            dense
            hide-details
          ></v-checkbox>
        </div>
      </template>
    </v-data-table>
    <DialogUserVerify
      v-if="dialog.verify"
      :user="selectUser"
      v-model="dialog.verify"
      @load="load"
    />

    <v-dialog
      v-if="dialog.topUp"
      v-model="dialog.topUp"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">儲值點數</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="selectUser.userID"
                  label="會員ID"
                  disabled
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="payload.branch"
                  :items="accessBranch"
                  label="店點"
                  required
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="payload.point"
                  @input="($event) => (payload.cash = Number($event))"
                  label="儲值點數"
                  type="number"
                  required
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="payload.cash"
                  label="現金收納"
                  type="number"
                  required
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog"> 取消 </v-btn>
          <v-btn color="blue darken-1" text @click="topUp"> 儲值 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import util from "@/mixins/util.vue";
import {
  translateDataKey,
  exportToExcel,
  reorderObjectKey,
} from "@/utils/exportExcel.js";
import DialogUserVerify from "@/components/dashboard/users/DialogUserVerify.vue";

export default {
  name: "",
  props: [],
  mixins: [util],
  components: { DialogUserVerify },
  data: () => ({
    dayjs,
    data: [],
    owners: [],
    topUpDialog: false,
    dialog: {
      topUp: false,
      verify: false,
    },
    selectUser: {},
    payload: {},
    keyword: "",
    isVerified: false,
    isOwner: false,
    headers: [
      {
        text: "使用者ID",
        value: "userID",
        align: "center",
        sortable: false,
        cellClass: "font-weight-regular",
      },
      {
        text: "點數",
        value: "balance",
        align: "right",
        sortable: true,
      },
      {
        text: "註冊時間",
        value: "createdAt",
        align: "center",
        sortable: true,
      },
      {
        text: "名稱",
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: "聯絡電話",
        value: "phone",
        align: "center",
        sortable: false,
      },
      {
        text: "生日",
        value: "birth",
        align: "center",
        sortable: false,
      },
      {
        text: "地址",
        value: "address",
        align: "left",
        sortable: false,
      },
      {
        text: "驗證",
        value: "verify",
        align: "center",
        sortable: false,
      },
      {
        text: "格主",
        value: "ichiban",
        align: "center",
        sortable: false,
      },
      {
        text: "操作",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    filterData() {
      let data = this.data;
      if (this.keyword) {
        data = data.filter((user) => user.userID.includes(this.keyword));
      }
      if (this.isVerified) {
        data = data.filter((user) => user.isVerified === true);
      }
      if (this.isOwner) {
        data = data.filter((user) => this.ownerIds.includes(user.userID));
      }
      return data;
    },
    ownerIds() {
      return this.owners.map((user) => user.userID);
    },
    ownerMap() {
      return _.keyBy(this.owners, "userID");
    },
  },
  async created() {
    await this.getOwners();
    await this.load();
    this.resetPayload();
  },
  methods: {
    async updateOwner(user, val) {
      const isOwner = this.ownerIds.includes(user.userID);
      const text = isOwner ? `將使用者從格主移除` : `確認將使用者註冊為格主`;
      const confirm = await this.$dialog.confirm({
        persistent: true,
        title: "",
        text,
      });
      if (!confirm) return;

      try {
        this.$vloading.show();
        if (!isOwner) {
          await this.axios.post("/ichibanOwner", {
            userID: user.userID,
          });
        } else {
          await this.axios.delete(`/ichibanOwner/${user.userID}`);
        }
        await this.getOwners();
      } catch (error) {
        this.$toast.error("更新失敗");
      } finally {
        this.$vloading.hide();
      }
    },
    async updateStatus(user) {
      const updateVal = !user.isVerified;
      if (updateVal && (!user.name || !user.phone)) {
        const confirm = await this.$dialog.warning({
          persistent: true,
          title: "",
          text: `請先更新姓名和電話，才可以驗證使用者`,
        });
        return;
      }

      const confirm = await this.$dialog.confirm({
        persistent: true,
        title: "",
        text: `確認將使用者改為${updateVal ? "驗證" : "未驗證"}`,
      });

      if (!confirm) return;

      try {
        await this.axios.put(`/users/validate/${user.userID}`, {
          isVerified: updateVal,
        });
        await this.load();
        this.$toast.success("更新成功");
      } catch (error) {
        this.$toast.error("更新失敗");
      }
    },
    async load() {
      this.$vloading.show();

      try {
        await this.getUsers();
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }

      this.$vloading.hide();
    },
    async getUsers() {
      const { data } = await this.axios.get("/users", {
        params: { store: "@695yimoc" },
      });
      this.data = data.sort(this.sortDateDesc("createdAt"));
    },
    async getOwners() {
      const { data } = await this.axios.get("/ichibanOwner");
      this.owners = data;
    },
    openDialog(user, type) {
      this.dialog[type] = true;
      this.selectUser = user;
    },
    closeDialog() {
      this.dialog = { topUp: false, verify: false };
      this.selectUser = {};
      this.resetPayload();
    },
    resetPayload() {
      this.payload = {
        branch: "",
        point: 0,
        cash: 0,
      };
    },
    async topUp() {
      if (!this.payload.branch) {
        return this.$toast.error("請填入店點");
      }
      if (!this.payload.point && this.payload.point !== 0) {
        return this.$toast.error("請填入點數");
      }
      if (!this.payload.cash && this.payload.cash !== 0) {
        return this.$toast.error("請填入金額");
      }

      try {
        await this.axios.post("/points/store", {
          userID: this.selectUser.userID,
          ...this.payload,
        });
        await this.load();
        this.closeDialog();
        this.$toast.success("儲值成功！");
      } catch (error) {
        console.log(error);
        this.$toast.error("儲值失敗！");
        return;
      }
    },
    exportExcel() {
      const omitKeys = ["_id", "createdAt", "UID", "store", "isVerified"];
      const keyTranslation = {
        userID: "帳號",
        balance: "剩餘點數",
        address: "地址",
        birth: "生日",
        name: "名字",
        phone: "電話",
      };
      const desiredKeyOrder = Object.keys(keyTranslation);
      const data = translateDataKey(
        this.filterData.map((item) => reorderObjectKey(item, desiredKeyOrder)),
        keyTranslation,
        omitKeys
      );
      exportToExcel(data, `會員資料`);
    },
  },
};
</script>

<style scoped lang="scss">
.empty-label {
  position: relative;
  padding-top: 0;
  margin-top: 0;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 0;
  }
}
</style>
